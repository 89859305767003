<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererFolder from '@core/layouts/components/layout-content-renderer/LayoutContentRendererFolder.vue'
import LayoutContentRendererCustomer from '@core/layouts/components/layout-content-renderer/LayoutContentRendererCustomer.vue'
import LayoutContentRendererOpenAi from '@core/layouts/components/layout-content-renderer/LayoutContentRendererOpenAi.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import { adminSidebar, clientSidebar } from '@/navigation/vertical'
import router from '@/router'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererFolder,
    LayoutContentRendererCustomer,
    LayoutContentRendererOpenAi,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-openAi') return 'layout-content-renderer-open-ai'
      if (rendererType === 'sidebar-customer') return 'layout-content-renderer-customer'
      if (rendererType === 'sidebar-folder') return 'layout-content-renderer-folder'
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden, verticalSidebar,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      visibilityStateHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)

    visibilityStateHandler()
    window.addEventListener('visibilitychange', visibilityStateHandler)
    window.addEventListener('beforeunload', visibilityStateHandler('offline'))

    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
      window.removeEventListener('visibilitychange', visibilityStateHandler)
    })

    const {
      user,
    } = adminSidebar()

    const {
      home,
    } = clientSidebar()

    const initSidebar = () => {
      verticalSidebar.value = []
      verticalSidebar.value = verticalSidebar.value.concat(home)
      verticalSidebar.value = verticalSidebar.value.concat(user)
      // store.dispatch('verticalMenu/resetSidebar')
      //   .then(response => {
      //     const { ability } = response.data.data

      //     const resolveAbility = {
      //       state: true,
      //       config: ability.config ? ability.config : 'none',
      //       question: ability.question ? ability.question : 'none',
      //       user: ability.user ? ability.user : 'none',
      //     }

      //     store.commit('app/UPDATE_ABILITY_STATE', resolveAbility)
      //     if (ability.user !== 'none') verticalSidebar.value = verticalSidebar.value.concat(user)
      //     if (ability.question !== 'none') verticalSidebar.value = verticalSidebar.value.concat(question)
      //     if (ability.config !== 'none') verticalSidebar.value = verticalSidebar.value.concat(config)
      //     verticalSidebar.value = verticalSidebar.value.concat(analytics)
      //     // read write
      //   })
    }

    setTimeout(() => {
      if (router.currentRoute.meta.isAdmin) initSidebar()
    }, 100)

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
