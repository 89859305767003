export const adminSidebar = () => {
  const user = [
    // {
    //   title: '使用者',
    //   type: 1,
    //   icon: 'ServerIcon',
    //   imgIcon: '/admin/images/sideIcon/users.svg',
    //   children: [
    //     {
    //       title: '使用者列表',
    //       type: 1,
    //       icon: 'CastIcon',
    //       imgIcon: '/admin/images/sideIcon/users.svg',
    //       route: { name: 'admin-user' },
    //     },
    //     {
    //       title: '使用者詳情',
    //       type: 1,
    //       icon: 'CastIcon',
    //       imgIcon: '/admin/images/sideIcon/users.svg',
    //       route: { name: 'admin-user-view' },
    //     },
    //   ],
    // },
    {
      title: '員工列表',
      type: 1,
      icon: 'CastIcon',
      imgIcon: '/admin/images/sideIcon/users.svg',
      route: { name: 'admin-user' },
      // action: 'read',
      // resource: 'user',
    },
    {
      title: '部門列表',
      type: 1,
      icon: 'CastIcon',
      imgIcon: '/admin/images/sideIcon/department.svg',
      route: { name: 'admin-department' },
      // action: 'read',
      // resource: 'user',
    },
    {
      title: '地點列表',
      type: 1,
      icon: 'CastIcon',
      imgIcon: '/admin/images/sideIcon/place.svg',
      route: { name: 'admin-place' },
      // action: 'read',
      // resource: 'user',
    },
    {
      title: '品牌列表',
      type: 1,
      icon: 'CastIcon',
      imgIcon: '/admin/images/sideIcon/brand.svg',
      route: { name: 'admin-brand' },
      // action: 'read',
      // resource: 'user',
    },
    {
      title: '公司品牌',
      type: 1,
      icon: 'CastIcon',
      imgIcon: '/admin/images/sideIcon/brand-admin.svg',
      route: { name: 'admin-brand-admin' },
      // action: 'read',
      // resource: 'user',
    },
    {
      title: '設備管理',
      type: 1,
      icon: 'ServerIcon',
      imgIcon: '/admin/images/sideIcon/device.svg',
      children: [
        {
          title: '設備列表',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/device-list.svg',
          route: { name: 'admin-device' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: '主機管理',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/device-pc.svg',
          route: { name: 'admin-device-pc' },
          // action: 'read',
          // resource: 'user',
        },
        // {
        //   title: 'CPU庫存',
        //   type: 1,
        //   icon: 'CastIcon',
        //   imgIcon: '/admin/images/sideIcon/device-cpu.svg',
        //   route: { name: 'admin-device-cpu' },
        //   // action: 'read',
        //   // resource: 'user',
        // },
      ],
    },
    {
      title: '庫存管理',
      type: 1,
      icon: 'ServerIcon',
      imgIcon: '/admin/images/sideIcon/store.svg',
      children: [
        {
          title: 'CPU管理',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/device-cpu.svg',
          route: { name: 'admin-device-cpu' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: '記憶體管理',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/device-memory.svg',
          route: { name: 'admin-device-memory' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: '硬碟管理',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/device-disk.svg',
          route: { name: 'admin-device-disk' },
          // action: 'read',
          // resource: 'user',
        },
      ],
    },
    // {
    //   title: 'AI機器人列表',
    //   type: 1,
    //   icon: 'CastIcon',
    //   imgIcon: '/admin/images/sideIcon/open-ai.svg',
    //   route: { name: 'admin-openAI' },
    // },
    {
      title: 'AI機器人',
      type: 1,
      icon: 'ServerIcon',
      imgIcon: '/admin/images/sideIcon/open-ai.svg',
      children: [
        {
          title: '機器人設定',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/settings.svg',
          route: { name: 'admin-openAI' },
        },
        {
          title: '機器人對話',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/todo-list.svg',
          route: { name: 'admin-openAI-room' },
        },
      ],
    },
    {
      title: '客戶列表',
      type: 1,
      icon: 'CastIcon',
      imgIcon: '/admin/images/sideIcon/customer.svg',
      route: { name: 'admin-customer' },
    },
    {
      title: '待辦事項',
      type: 1,
      icon: 'ServerIcon',
      imgIcon: '/admin/images/sideIcon/todo.svg',
      children: [
        {
          title: '群組權限',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/todo-group.svg',
          route: { name: 'admin-todo-group' },
        },
        {
          title: '作業分類',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/todo-class.svg',
          route: { name: 'admin-todo-class' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: '項目設定',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/todo-execution.svg',
          route: { name: 'admin-todo-execution' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: '待辦列表',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/todo-list.svg',
          route: { name: 'admin-todo' },
          // action: 'read',
          // resource: 'user',
        },
      ],
    },
    {
      title: '快速功能',
      type: 1,
      icon: 'ServerIcon',
      imgIcon: '/admin/images/sideIcon/package.svg',
      children: [
        {
          title: '密碼產生器',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/random.svg',
          route: { name: 'admin-page-random' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: '圖片轉換',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/image-conversion.svg',
          route: { name: 'admin-page-image' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: '圖片轉Base64',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/base64.svg',
          route: { name: 'admin-page-base64-image' },
          // action: 'read',
          // resource: 'user',
        },
        {
          title: 'Base64轉碼',
          type: 1,
          icon: 'CastIcon',
          imgIcon: '/admin/images/sideIcon/convert.svg',
          route: { name: 'admin-page-base64-convert' },
          // action: 'read',
          // resource: 'user',
        },
      ],
    },
    {
      title: '個人雲端',
      type: 1,
      icon: 'CastIcon',
      imgIcon: '/admin/images/sideIcon/folder.svg',
      route: { name: 'folder' },
      // action: 'read',
      // resource: 'user',
    },
  ]

  const article = []

  return {
    user,
    article,
  }
}

export const clientSidebar = () => {
  const home = [{
    title: '後台首頁',
    type: 1,
    icon: 'CastIcon',
    imgIcon: '/admin/images/sideIcon/home.svg',
    route: { name: 'admin-home' },
  }]

  return {
    home,
  }
}
